import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// guards
import useAuth from "../hooks/useAuth";
// layouts
import DashboardLayout from "../layouts/dashboard";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import AccessGuard from "src/guards/AccessGuard";
// import Assets from 'src/pages/dashboard/Asset';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "setup", element: <SetUp /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <GeneralApp /> },
        { 
          path: "budget-pacing",
          children: [
            { path: "", element: <AccessGuard element='budget-pacing' ><Budgets/></AccessGuard> },
            { path: "settings", element: <AccessGuard element='budget-pacing' roles={["admin", "manager", "search-analyst"]} ><BudgetsSettings /></AccessGuard> },
          ]
        },
        { path: "assets", element: <AccessGuard element='assets' ><Assets /></AccessGuard> },
        { path: "analytics", element: <AccessGuard element='analytics' ><Analytics /></AccessGuard> },
        { path: "specials", element: <AccessGuard element='specials' ><Specials /></AccessGuard> },
        // { path: 'users', element: <AccessGuard element='users' roles={['admin']}><Users /></AccessGuard> },
        { path: "sites", element: <AccessGuard element='sites' roles={["admin"]}><Sites /></AccessGuard> },
        { path: "jobs", element: <AccessGuard element='jobs' roles={["admin"]}><Jobs /></AccessGuard> },
        { path: "tokens", element: <AccessGuard element='tokens' roles={["admin"]}><Tokens /></AccessGuard> },
        {
          path: "user",
          children: [
            { path: "new", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
      ],
    },
    { path: "/", element: <Navigate to="/dashboard" replace /> },
    { path: "signatures", element: <Signatures/> },
    { path: "*", element: <NotFound/> },
  ]);
}

// IMPORT COMPONENTS

const Signatures = Loadable(lazy(() => import("../pages/signatures/Signatures")));
// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const ResetPassword = Loadable(lazy(() => import("../pages/auth/ResetPassword")));
const SetUp = Loadable(lazy(() => import("../pages/auth/SetUp")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(lazy(() => import("../pages/dashboard/GeneralApp")));
const Budgets = Loadable(lazy(() => import("../pages/dashboard/budgetPacing/overview")));
const BudgetsSettings = Loadable(lazy(() => import("../pages/dashboard/budgetPacing/settings")));
const Assets = Loadable(lazy(() => import("../pages/dashboard/Assets")));
const Specials = Loadable(lazy(() => import("../pages/dashboard/Specials")));
const Analytics = Loadable(lazy(() => import("../pages/dashboard/Analytics")));
const Sites = Loadable(lazy(() => import("../pages/dashboard/Sites")));
const Tokens = Loadable(lazy(() => import("../pages/dashboard/Tokens")));
const Jobs = Loadable(lazy(() => import("../pages/dashboard/Jobs")));
const UserAccount = Loadable(lazy(() => import("../pages/dashboard/UserAccount")));
const UserCreate = Loadable(lazy(() => import("../pages/dashboard/UserCreate")));

const NotFound = Loadable(lazy(() => import("../pages/Page404")));