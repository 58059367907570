// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, Container, Typography } from "@mui/material";
// components
import Page from "../../components/Page";
// sections
import { LoginForm } from "../../sections/auth/login";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <Page title="Login">
      <RootStyle>
        <LogoOnlyLayout />

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to iFrog Portal
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <LoginForm />

          </ContentStyle>
        </Container>

      </RootStyle>
    </Page>
  );
}
