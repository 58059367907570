// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  setUp: path(ROOTS_AUTH, "/setup"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  // verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_DASHBOARD = {
  root: "/",
  general: {
    app: "/dashboard",
    analytics: "/analytics",
    specials: "/specials",
  },
  budgetPacing: {
    app: "/budget-pacing",
    settings: "/budget-pacing/settings"
  },
  assets: {
    main: "/assets",
    logos: "/assets/logos",
    jellybeans: "/assets/jellybeans",
  },
  management: {
    users: "/users",
    sites: "/sites",
    jobs: "/jobs",
    tokens: "/tokens",
  },
  user: {
    account: "/user/account"
  },
  permissionDenied: "/permission-denied",
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
