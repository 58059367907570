import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {

  const logo = (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64">
        <g>
          <path d="M60.92,3v58h-58V3H60.92 M63.92,0h-64v64h64V0L63.92,0z"/>
        </g>
        <g>
          <path d="M9.19,36.88h2.56v-6.26H9.19v-2.49h8.1v2.49h-2.56v6.26h2.56v2.49h-8.1V36.88z"/>
          <path d="M20.07,28.13h7.7v2.49h-4.72v2.15h4.05v2.49h-4.05v4.12h-2.98V28.13z"/>
          <path d="M29.94,28.13h4.22c2.42,0,4.5,0.9,4.5,3.74c0,2.75-2.15,3.95-4.5,3.95h-1.25v3.56h-2.98V28.13z M34.02,33.46
            c1.18,0,1.73-0.59,1.73-1.59c0-1-0.62-1.38-1.73-1.38h-1.11v2.98H34.02z"/>
          <g>
            <path d="M9.19,43.41h2.98v8.76h4.79v2.49H9.19V43.41z"/>
            <path d="M18.51,48.98c0-3.81,1.87-5.78,4.6-5.78c2.73,0,4.6,1.97,4.6,5.78c0,3.91-1.87,5.88-4.6,5.88
              C20.38,54.87,18.51,52.9,18.51,48.98z M24.67,48.98c0-2.15-0.59-3.22-1.56-3.22c-0.97,0-1.56,1.07-1.56,3.22
              c0,2.25,0.59,3.32,1.56,3.32C24.08,52.31,24.67,51.23,24.67,48.98z"/>
            <path d="M29,49.12c0-3.81,2.37-5.92,5.29-5.92c1.52,0,2.61,0.69,3.36,1.45l-1.63,1.83c-0.47-0.45-0.92-0.73-1.73-0.73
              c-1.23,0-2.23,1.21-2.23,3.25c0,2.11,0.78,3.29,2.39,3.29c0.28,0,0.59-0.07,0.76-0.21v-1.56h-1.4v-2.42h4.05v5.37
              c-0.76,0.69-2.15,1.38-3.58,1.38C31.39,54.87,29,52.96,29,49.12z"/>
            <path d="M39.28,48.98c0-3.81,1.87-5.78,4.6-5.78c2.73,0,4.6,1.97,4.6,5.78c0,3.91-1.87,5.88-4.6,5.88
              C41.16,54.87,39.28,52.9,39.28,48.98z M45.45,48.98c0-2.15-0.59-3.22-1.56-3.22c-0.97,0-1.56,1.07-1.56,3.22
              c0,2.25,0.59,3.32,1.56,3.32C44.86,52.31,45.45,51.23,45.45,48.98z"/>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
