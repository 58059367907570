import { m } from "framer-motion";
// @mui
import { Container, Typography } from "@mui/material";
// hooks
import useAuth from "../hooks/useAuth";
// components
import { MotionContainer, varBounce } from "../components/animate";
// assets
import { ForbiddenIllustration } from "../assets";

// ----------------------------------------------------------------------

type AccessBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  element?: string;
  children: React.ReactNode;
};

function findCommonElements(arr1: string[], arr2: string[]) {
  return arr1.some(item => arr2.includes(item));
}

export default function AccessBasedGuard({ hasContent, roles, element, children }: AccessBasedGuardProp) {
  const { user } = useAuth();
  const currentAccess = user?.access ? user.access : [];
  const currentRoles = user?.roles ? user.roles : [];

  if ((typeof element !== "undefined" && !currentAccess.includes(element)) || (typeof roles !== "undefined" && !findCommonElements(roles, currentRoles))) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: "center" }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
