// ----------------------------------------------------------------------

function getFirstCharacter(name: string) {
  return name && name.charAt(0).toUpperCase();
}

export default function createAvatar(name: string) {
  return {
    name: getFirstCharacter(name),
    color: "primary",
  } as const;
}
