import { styled, useTheme } from "@mui/material/styles";
import { alpha, Box, Button, Divider, List, ListItemButton, ListItemText, Popover, Typography, Collapse } from "@mui/material";
// hooks
import useCurrentSite from "src/hooks/useCurrentSite";
// components
import DealerIcon from "../../../components/DealerIcon";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

type GroupItemProps = {
  group: { id: string, name: string, sites: any[] };
};

type GroupListProps = {
  groups: { id: string, name: string, sites: any[] }[];
};

function SiteGroupSelection(props:GroupItemProps) {
  const { onChangeSite } = useCurrentSite();
  const handleSiteChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, site: { id: string; name: string }, groupName: string) => { 
    onChangeSite(site, groupName);
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={props.group.name} />
        {open ? <Iconify icon={"akar-icons:chevron-up"} width={16} height={16} /> : <Iconify icon={"akar-icons:chevron-down"} width={16} height={16} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          { props.group.sites.map((site: { id: string; name: string; }) => 
            <ListItemButton key={site.id} onClick={(e) => handleSiteChange(e, site, props.group.name)}>
              <ListItemText sx={{ paddingLeft: "20px" }} primary={site.name} />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
}


function SiteGroupSelectionList(props:GroupListProps) {
  
  const { onChangeSite } = useCurrentSite();
  const handleSiteChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, site: { id: string; name: string }, groupName: string) => { 
    onChangeSite(site, groupName);
  };

  const siteGroups = props.groups?.map((group: { id: string; name: string; sites: any[]; }) =>
    <SiteGroupSelection key={group.id} group={group} />
  );
  
  return (
    <List>
      <ListItemButton onClick={(e) => handleSiteChange(e, { id: "-1", name: "All Sites" }, "All Groups")}>
        <ListItemText primary='All Sites' />
      </ListItemButton>
      {siteGroups}
    </List>
  );
}


export default function NavbarAccount({ isCollapse }: Props) {
  const theme = useTheme();
  const { siteName, groupName } = useCurrentSite();
  const { user } = useAuth();

  const siteGroups = user?.siteGroups;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Popover 
        anchorReference="anchorPosition"
        anchorPosition={{ top: 98, left: 300 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            // ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper }),
            boxShadow: `10px 10px 32px 5px ${alpha(
              theme.palette.mode === "light" ? theme.palette.grey[600] : theme.palette.common.black,
              0.16
            )}`,
            width: "300px",
          }
        }}
      >
        <Typography 
          variant="h6"
          sx={{ padding: "20px" }}
        >Change Site</Typography>
        <Divider sx={{ borderStyle: "dashed" }} />
        <SiteGroupSelectionList groups={siteGroups}/>
      </Popover>
      <Button onClick={handleClick} sx={{ padding: "0px" }}>
        <RootStyle
          sx={{
            ...(isCollapse && {
              bgcolor: "transparent",
            }),
            width: "100%",
          }}
        >
          <DealerIcon 
            sx={{
              ...(!isCollapse && {
                ml: 0,
                width: 0,
                display: "none"
              }),
            }}
          />
          <Box
            sx={{
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),

            }}
          >
            <Typography variant="subtitle2" noWrap sx={{ textAlign: "left" }}>
              {siteName}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: "text.secondary", textAlign: "left" }}>
              {groupName}
            </Typography>
          </Box>
        </RootStyle>
      </Button>
    </>
  );
}
