import { ReactNode, createContext } from "react";
// hooks
import useLocalStorage from "../hooks/useLocalStorage";
// ----------------------------------------------------------------------

type SiteContextProps = {
  siteID: string,
  siteName: string,
  groupName: string,
  onChangeSite: (site: { id: string; name: string }, groupName: string) => void,
};

const initialState: SiteContextProps = {
  siteID: "-1",
  siteName: "All Sites",
  groupName: "All Groups",
  onChangeSite: () => { /* do nothing */ },
};

const CurrentSiteContext = createContext(initialState);

type CurrentSiteProviderProps = {
  children: ReactNode;
};

function CurrentSiteProvider({ children }: CurrentSiteProviderProps) {
  const [currentSite, setCurrentSite] = useLocalStorage("currentSite", {
    siteID: initialState.siteID,
    siteName: initialState.siteName,
    groupName: initialState.groupName,
  });

  const onChangeSite = (site: { id: string; name: string }, groupName: string) => {
    setCurrentSite({
      siteID: site.id,
      siteName: site.name,
      groupName,
    });
  };

  return (
    <CurrentSiteContext.Provider
      value={{
        ...currentSite,
        onChangeSite,
      }}
    >
      {children}
    </CurrentSiteContext.Provider>
  );
}

export { CurrentSiteProvider, CurrentSiteContext };
