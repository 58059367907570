import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CurrentSiteProvider } from "./contexts/CurrentSiteContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

import { AuthProvider } from "./contexts/FirebaseContext";

//
import App from "./App";

// ----------------------------------------------------------------------

const container = document.getElementById("root");
if (!container) console.log("Container could not be found");
else {
  const root = createRoot(container);
  root.render(
    <AuthProvider>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <CurrentSiteProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CollapseDrawerProvider>
            </CurrentSiteProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </AuthProvider>,
  );
}
