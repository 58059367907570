import firebase from "../firebase";
import { IUserData } from "../@types/user.type";
const db = firebase.collection("/users");

class UserDataService {
  getAll() {
    return db;
  }

  create(user: IUserData, uid: string | undefined) {
    if (!uid) return null;
    return db.doc(uid).set(user);
  }

  update(id: string, value: any) {
    return db.doc(id).update(value);
  }

  delete(id: string) {
    return db.doc(id).delete();
  }
}

export default new UserDataService();