// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

const ICONS = {
  dashboard: <Iconify icon={"bx:home"} />,
  budgetPacing: <Iconify icon={"bx:tachometer"} />,
  assets: <Iconify icon={"bx:image"} />,
  specials: <Iconify icon={"bx:news"} />,
  analytics: <Iconify icon={"bx:bar-chart-square"} />,
  users: <Iconify icon={"bx:group"} />,
  sites: <Iconify icon={"bx:store-alt"} />,
  jobs: <Iconify icon={"bx:game"} />,
  tokens: <Iconify icon={"bx:key"} />,
};

type NavItem = {
  title: string;
  path: string;
  icon?: JSX.Element;
  roles?: string[];
  caption?: string;
  children?: NavItem[];
  disabled?: boolean;
  info?: JSX.Element;
  element?: string;
}

type NavSection = {
  subheader: string;
  items: NavItem[];
  roles?: string[];
};

const navConfig: NavSection[] = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      { title: "home", path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: "budget pacing", path: PATH_DASHBOARD.budgetPacing.app, icon: ICONS.budgetPacing, element: "budget-pacing" },
      { 
        title: "asset manager",
        path: PATH_DASHBOARD.assets.main,
        icon: ICONS.assets,
        element: "assets",
        children: [
          { title: "logos", path: PATH_DASHBOARD.assets.logos },
          { title: "jellybeans", path: PATH_DASHBOARD.assets.jellybeans },
        ],
      },
      { title: "analytics", path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics, element: "analytics", disabled: true, caption: "Coming Soon" },
      { title: "specials", path: PATH_DASHBOARD.general.specials, icon: ICONS.specials, element: "specials", disabled: true, caption: "Coming Soon" },
    ],
  },

  {
    subheader: "management",
    roles: ["admin"],
    items: [
      { title: "users", path: PATH_DASHBOARD.management.users, icon: ICONS.users, element: "users" },
      { title: "sites", path: PATH_DASHBOARD.management.sites, icon: ICONS.sites, element: "sites" },
      { title: "jobs", path: PATH_DASHBOARD.management.jobs, icon: ICONS.jobs, element: "jobs" },
      { title: "tokens", path: PATH_DASHBOARD.management.tokens, icon: ICONS.tokens, element: "tokens" },
    ],
  },
];

export default navConfig;
