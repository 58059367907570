// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar, { Props as AvatarProps } from "./Avatar";

// ----------------------------------------------------------------------

export default function DealerIcon({ ...other }: AvatarProps) {
  const dealer: any = {};// = { icon: 'https://ca.slack-edge.com/T4H8YU6NT-UV6SYF75X-e49485d1495b-64', displayName: 'PAG' };

  return (
    <Avatar
      src={dealer?.icon}
      alt={dealer?.displayName}
      color={dealer?.icon ? "default" : createAvatar(dealer?.displayName).color}
      {...other}
    >
      {createAvatar(dealer?.displayName).name ?? "A"}
    </Avatar>
  );
}
