import { Helmet } from "react-helmet-async";
import { forwardRef, ReactNode } from "react";
// @mui
import { Box, BoxProps } from "@mui/material";
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = "", ...other }, ref) => {
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{`${title} | IFP`}</title>
      </Helmet>
      {children}
    </Box>
  );
});

export default Page;
